<template>
  <div>
    <el-dialog title="查看微课"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               v-dialogDrag
               :append-to-body="true"
               width="41.67%"
               :before-close="handleClose">
      <div class="ifreme_warp"
           v-if="dialogVisible">
        <video controls
               :src="url"
               alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      url: '',
    }
  },
  mounted () {

  },
  methods: {
    handleClose () {
      this.innerVisible = false
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.ifreme_warp {
  overflow: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  width: 83.33%;
  height: 80.25%;
  margin: 0 auto;
  video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #e5e5e5;
  }
  audio {
    // width: 960px;
    // height: 540px;
  }
}
::v-deep .el-dialog__body {
  height: 400px;
}
.download {
  div {
    text-align: center;
    margin-bottom: 30px;
  }
  font-size: 18px;
  font-weight: 500;
  color: black;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog__body {
  padding-bottom: 0;
}
::v-deep .el-button--medium {
  width: 90px;
  height: 36px;
  border-radius: 28px;
}
</style>